import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Avatar,
  Box,
  DialogContent,
  Grid,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Tab,
  Tabs
} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import { DEFAULT_OFFER_IMAGE } from 'src/utils/constants';
import DoneIcon from '@mui/icons-material/Done';
import TemplateImagesList from './TemplateImagesList';
import { getTheFormat } from 'src/utils/utils';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500
  },
  avatar: {
    height: "150px !important",
    width: '100% !important'
  },
  imageShadow: {
    boxShadow: '0px 6px 12px 1px rgba(20,20,20,0.4)'
  }
}));
export default function ImagesUploadDialog({
  open,
  handleClose,
  handleCapture,
  setFieldValue
}) {
  const classes = useStyles();

  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [defaultImages, setDefaultImages] = useState(DEFAULT_OFFER_IMAGE);
  const [selectedImages, setSelectedImages] = useState([]);
  const templateImages = useRef([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const handleSave = () => {
    const selectedDefaultImages = defaultImages.filter((item) => item.isSelect);
    const selectedTemplateImages = templateImages.current.filter(
      (item) => item.isSelect
    );
    handleCapture(
      [...selectedImages, ...selectedDefaultImages, ...selectedTemplateImages],
      setFieldValue
    );
    handleClose();
  };
  const deleteImage = (index, setFieldValue, type) => {
    console.log(index);
    const offerImgs = selectedImages.filter((img, key) => {
      return key !== index;
    });
    if (type === 'newImage') {
      setSelectedImages(offerImgs);
    } else if (type === 'existingImage') {
      const filteredArray = selectedImages.filter((img, key) => {
        if (key === parseInt(index)) {
          img.isDeleted = true;
        }
        return img;
      });
      setSelectedImages(filteredArray);
    }
  };

  const handleCaptureImages = ({ target }) => {
    let files = target.files;

    let filesArray = [...selectedImages, ...files];

    setSelectedImages(filesArray);

    target.value = '';
  };

  const selectImage = (index) => {
    defaultImages[index].isSelect = !defaultImages[index].isSelect;
    setDefaultImages([...defaultImages]);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle id="alert-dialog-title">Upload Images</DialogTitle>
      <DialogContent>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs "
        >
          <Tab label="Select from computer" {...a11yProps(0)} />
          <Tab label="Select default images" {...a11yProps(1)} />
          <Tab label="Your Templates" {...a11yProps(2)} />
        </Tabs>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Box
              display="flex"
              justifyContent="center"
              p={1}
              component={'span'}
            >
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file-dialog"
                type="file"
                onChange={(e) => {
                  handleCaptureImages(e);
                }}
                name="tempofferImgs"
                multiple
              />
              <label htmlFor="raised-button-file-dialog">
                <Button component="span">Add images</Button>
              </label>
            </Box>
            <Grid container direction="row" spacing={2}>
              {selectedImages &&
                selectedImages.map((item, index) => (
                  <Grid key={index} item xs={3}>
                    <ImageListItem
                      key={index}
                      component={'span'}
                      classes={{ tile: classes.imageShadow }}
                    >
                      {item.name && (
                        <span>
                          <Avatar
                            component={'span'}
                            alt={item.name}
                            src={URL.createObjectURL(item)}
                            variant="square"
                            className={classes.avatar}
                          />
                          <ImageListItemBar
                            title={item.name || item.imagePath}
                            subtitle={
                              <span>
                                Format:{' '}
                                {getTheFormat(item.name || item.imagePath)}
                              </span>
                            }
                            actionIcon={
                              <IconButton
                                onClick={() =>
                                  deleteImage(index, setFieldValue, 'newImage')
                                }
                                aria-label={`info about ${item.name}`}
                                className={classes.icon}
                              >
                                <DeleteIcon
                                  className={classes.statsIcon}
                                  color="error"
                                />
                              </IconButton>
                            }
                          />
                        </span>
                      )}
                    </ImageListItem>
                  </Grid>
                ))}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Grid container spacing={2}>
              {defaultImages.map((item, index) => (
                <Grid item xs={2} md={3} key={index}>
                  <ImageListItem
                    key={index}
                    component={'span'}
                    style={{ cursor: 'pointer' }}
                    onClick={() => selectImage(index)}
                  >
                    <Avatar
                      component={'span'}
                      alt={item.name}
                      src={`/static/images/${item.imagePath}`}
                      variant="square"
                      className={classes.avatar}
                    />
                    <ImageListItemBar
                      title={item.name}
                      subtitle={
                        <span>Format: {getTheFormat(item.imagePath)}</span>
                      }
                      actionIcon={
                        <IconButton
                          aria-label={`info about ${item.imagePath}`}
                          className={classes.icon}
                        >
                          {item.isSelect && (
                            <DoneIcon
                              style={{ color: 'rgb(255,247,0)' }}
                              fontSize="large"
                            />
                          )}
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <TemplateImagesList data={templateImages} />
          </TabPanel>
        </SwipeableViews>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
