import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  FormHelperText,
  Stack
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Services from 'src/services/Services';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { GlobalContext } from '../../../context/GlobalState';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@mui/material'
import Moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const useStyles = makeStyles(() => ({
  root: {},
  mgLeft: {
    marginLeft: 10
  }
}));

const SlotHistoryForm = ({ className, type, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [bannersData, setBannersData] = useState([]);
  const [offersData, setOffersData] = useState([]);
  //const [userID, setUserID] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const alertPosition = { horizontal: 'right', vertical: 'top' };
  const navigate = useNavigate();
  const { setLoading, setHistoryData } = useContext(GlobalContext);

  const fetchBanners = async () => {
    try {
      setLoading(true);
      const resultData = await Services.getBannerImages('all'); //to get all the banners data
      setBannersData(resultData.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      enqueueSnackbar('Something went wrong, Please try again...!', {
        variant: 'error',
        anchorOrigin: alertPosition
      });
      navigate('/app/dashboard');
    }
  };
  const fetchOffers = async () => {
    try {
      setLoading(true);
      const resultData = await Services.getAllOffers(); //to get all the banners data
      const offersArray = resultData.data.filter((offer) => offer.status === 2);
      setOffersData(offersArray);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      enqueueSnackbar('Something went wrong, Please try again...!', {
        variant: 'error',
        anchorOrigin: alertPosition
      });
      navigate('/app/dashboard');
    }
  };

  useEffect(() => {
    if (type === 'banner') {
      fetchBanners();
    } else if (type === 'offer') {
      fetchOffers();
    }
    setValues({
      fromDate: dayjs(new Date()),
      toDate: dayjs(new Date()),
      slotType: type
    });

    //getProfiledata()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHistory = async (values, setSubmitting) => {
    try {
      setLoading(true);
      console.log(values);
      const requestData = {
        ...values,
        fromDate: Moment(values.fromDate).format('YYYY-MM-DD'),
        toDate: Moment(values.toDate).format('YYYY-MM-DD')
      };
      let historyData = { data: [] };
      if (type === 'banner') {
        historyData = await Services.getBannerHistory(requestData);
      } else if (type === 'offer') {
        historyData = await Services.getOfferHistory(requestData);
      }
      setHistoryData(historyData.data);

      setSubmitting(false);
      setLoading(false);
      //  enqueueSnackbar('Data updated successfully...!',  { variant: "success" ,"anchorOrigin" : alertPosition} );
    } catch (e) {
      console.log(e);
      setSubmitting(false);
      setLoading(false);
      enqueueSnackbar('Something went wrong, Please try again...!', {
        variant: 'error',
        anchorOrigin: alertPosition
      });
    }
  };
  const optionName = (name, createdDate, type) => {
    if (type === 'banner') {
      name = name.split('/')[1];
    }
    return name;
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...values }}
      validationSchema={Yup.object().shape({
        fromDate: Yup.date().required('From date is required'),
        toDate: Yup.date().required('To date is required'),
        slotType: Yup.string(),
        banner: Yup.string().nullable(),
        offer: Yup.string().nullable()
      })}
      onSubmit={(values, { setSubmitting }) =>
        getHistory(values, setSubmitting)
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setValues,
        setFieldValue
      }) => (
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >

          <Grid container direction={'row'} spacing={1} alignItems={'center'}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item md={3} xs={12}>
                <DatePicker
                  fullWidth
                  variant="inline"
                  error={Boolean(touched.fromDate && errors.fromDate)}
                  helperText={touched.fromDate && errors.fromDate}
                  format="DD/MM/YYYY"
                  name="fromDate"
                  id="date-picker-fromDate"
                  label="From Date"
                  value={values?.fromDate || null}
                  onChange={(value) => setFieldValue('fromDate', value)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <DatePicker
                  error={Boolean(touched.toDate && errors.toDate)}
                  helperText={touched.toDate && errors.toDate}
                  fullWidth
                  variant="inline"
                  format="DD/MM/YYYY"
                  name="toDate"
                  id="date-picker-toDate"
                  label="To Date"
                  value={values?.toDate || null}
                  onChange={(value) => setFieldValue('toDate', value)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </Grid>
            </LocalizationProvider>
            <input
              type="hidden"
              name="slotType"
              value={values.slotType || ''}
            />
            {type === 'banner' && bannersData.length > 0 && (
              <>
                <Grid item md={3} xs={12}>
                  <Autocomplete
                    id="Banners"
                    options={bannersData}
                    value={values.banner || null}
                    name="banner"
                    getOptionLabel={(option) =>
                      typeof option === 'string'
                        ? option
                        : optionName(
                          option.imagePath,
                          option.createdAt,
                          'banner'
                        )
                    }
                    onChange={(e, value) => setFieldValue('banner', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select banner"
                        variant="outlined"
                      />
                    )}
                  />
                  <FormHelperText
                    className={`${classes.mgLeft} ${errors.banner}?  Mui-error Mui-required: ''`}
                  >
                    {errors.banner}
                  </FormHelperText>
                </Grid>
              </>
            )}
            {type === 'offer' && offersData.length > 0 && (
              <>
                <Grid item md={3} xs={12}>
                  <Autocomplete
                    fullWidth
                    id="offers"
                    options={offersData}
                    value={values.offer || null}
                    name="offer"
                    getOptionLabel={(option) =>
                      typeof option === 'string'
                        ? option
                        : optionName(
                          option.offerName,
                          option.createdAt,
                          'offer'
                        )
                    }
                    onChange={(e, value) => {
                      setFieldValue('offer', value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select offer"
                        variant="outlined"
                      />
                    )}
                  />
                  <FormHelperText
                    className={`${classes.mgLeft} ${errors.offer}?  Mui-error Mui-required: ''`}
                  >
                    {errors.offer}
                  </FormHelperText>
                </Grid>
              </>
            )}
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                Search
              </Button>
            </Grid>
          </Grid>

        </form>
      )}
    </Formik>
  );
};

SlotHistoryForm.propTypes = {
  className: PropTypes.string
};

export default SlotHistoryForm;
