import { colors } from '@mui/material';
import { createTheme } from '@mui/material';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: "#ef5350c7"
    },
    secondary: {
      main: colors.indigo[400]
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.

    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.1,
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography,
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: { color: "#dd5c59" }
      }
    },
    MuiButton: {
      defaultProps: {
        size: 'small'

      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small'
      }
      ,
      MuiAutocomplete: {
        defaultProps: {
          size: "small"
        }
      },
      MuiSelect: {
        defaultProps: {
          size: "small"
        }
      }
    }
  }
});
export default theme