import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  CardActionArea
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { GOOGLE_STORAGE_PUBLIC_URL } from '../../../utils/config';
import { GlobalContext } from '../../../context/GlobalState';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  avatar: {
    height: "200px !important",
    width: '100% !important'
  },
  cutomStatus: {
    marginLeft: 10,
    borderRadius: 10,
    padding: 3,
    paddingLeft: 15,
    paddingRight: 15
  },
  approved: {
    color: '#4caf50',
    backgroundColor: 'rgba(76, 175, 80, 0.08)'
  },
  rejected: {
    color: '#f44336',
    backgroundColor: 'rgba(244, 67, 54, 0.08)'
  },
  submitted: {
    color: '#ff9800',
    backgroundColor: 'rgba(255, 152, 0, 0.08)'
  }
}));

const ProductCard = ({ className, store, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setSelectedStore } = useContext(GlobalContext);
  const gotoDetailedView = () => {
    console.log(store);
    setSelectedStore(store);
    navigate('/app/store/details');
  };
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardActionArea onClick={gotoDetailedView}>
        <CardContent>
          <Box display="flex" justifyContent="center" mb={3}>
            <Avatar
              alt={store.name}
              src={
                GOOGLE_STORAGE_PUBLIC_URL + store.owner + '/' + store.profilepic
              }
              variant="square"
              className={classes.avatar}
            ></Avatar>
          </Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            {store.name}
          </Typography>
          <Typography align="center" color="textPrimary" variant="body1">
            {store.address}
          </Typography>
        </CardContent>
      </CardActionArea>
      <Box flexGrow={1} />
      <Divider />
      <Box p={2}>
        <Grid container justifyContent="space-between" spacing={2} sx={{ marginTop: "-6px" }}>
          <Grid className={classes.statsItem} item>
            <AccessTimeIcon className={classes.statsIcon} color="action" />
            <Typography color="textSecondary" display="inline" variant="body2">
              {`Updated ${moment(store.updatedAt).fromNow()}`}
            </Typography>
          </Grid>
          <Grid item className={`${classes.cutomStatus} ${store.status === 1
            ? classes.submitted
            : store.status === 2
              ? classes.approved
              : classes.rejected
            }`}>
            {store.status === 1 ? "Submitted" : (store.status === 2 ? "Approved" : "Rejected")}
          </Grid>
          {/* <Grid
            className={classes.statsItem}
            item
          >
            <VisibilityIcon
              className={classes.statsIcon}
              color="action"
            />
            <Typography
              color="textSecondary"
              display="inline"
              variant="body2"
            >
              {' '}
              {' '}
              views
            </Typography>
          </Grid> */}
        </Grid>
      </Box>
    </Card>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  store: PropTypes.object.isRequired
};

export default ProductCard;
