import React, { useContext } from 'react';
import {
  Avatar,
  ButtonBase,
  colors,
  Grid,
  Paper,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { GOOGLE_STORAGE_PUBLIC_URL } from 'src/utils/config';
import { GlobalContext } from '../../../context/GlobalState';
import Services from '../../../services/Services';
import ConfirmDialog from './ConfirmDialog';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  avatar: {
    height: "200px !important",
    width: '100% !important'
  },
  paper: {
    padding: theme.spacing(2),
    maxWidth: '100%'
  },
  image: {
    width: 128,
    height: 128
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  statsIcon: {
    color: colors.green[300]
  },
  editIcon: {
    color: colors.blue[300]
  }
}));
const StoreInfo = ({ store }) => {
  const classes = useStyles();
  const { selectedStore, setSelectedStore } = useContext(GlobalContext);
  const alertPosition = { horizontal: 'right', vertical: 'top' };
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const editStore = () => {
    console.log('edit store');
    navigate('/app/store/edit');
  };
  const deleteStore = async () => {
    try {
      console.log('delete store');

      console.log(selectedStore);
      const responseData = await Services.deleteStore(selectedStore._id);
      if (responseData.status === 200) {
        setSelectedStore({});
        enqueueSnackbar('Store Deleted successfully...!', {
          variant: 'success',
          anchorOrigin: alertPosition
        });

        navigate('/app/stores');
      } else {
        enqueueSnackbar('Something went wrong, Please try again...!', {
          variant: 'error',
          anchorOrigin: alertPosition
        });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Something went wrong, Please try again...!', {
        variant: 'error',
        anchorOrigin: alertPosition
      });
    }
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Avatar
              alt={store.name}
              src={
                GOOGLE_STORAGE_PUBLIC_URL + store.owner + '/' + store.profilepic
              }
              variant="square"
              className={classes.avatar}
            />
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="h4">
                  {store.name}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {store.address}
                </Typography>
                <Typography variant="body2" color="textSecondary"></Typography>
              </Grid>
              <Grid>
                <Typography variant="body2" style={{ textAlign: 'center' }}>
                  <ConfirmDialog
                    deleteItem={deleteStore}
                    buttonText="Delete Store"
                    message="Are you sure, Do you want to delete the store?"
                  >
                    <DeleteIcon></DeleteIcon>
                  </ConfirmDialog>
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                <ButtonBase onClick={editStore}>
                  <EditIcon className={classes.editIcon} color="action" />
                </ButtonBase>
                <ButtonBase>
                  {store.status === 1 ? (
                    <Tooltip title="Submitted">
                      <ArrowUpwardIcon color="secondary" />
                    </Tooltip>
                  ) : store.status === 2 ? (
                    <Tooltip title="Approved">
                      <VerifiedUserIcon
                        className={classes.statsIcon}
                        color="action"
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Rejected">
                      <CancelIcon color="error" />
                    </Tooltip>
                  )}
                </ButtonBase>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default StoreInfo;
