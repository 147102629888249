import React, { useEffect, useRef, useState } from 'react';
import useTemplatesList from 'src/hooks/useTemplatesList';
import { GOOGLE_STORAGE_PUBLIC_URL, TEMPLATE_PATH } from 'src/utils/config';
import {
    Avatar,
    CircularProgress,
    Grid,
    ImageListItem,
    ImageListItemBar,
    IconButton
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DoneIcon from '@mui/icons-material/Done';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500
    },
    avatar: {
        height: "150px !important",
        width: '100% !important'
    },
    imageShadow: {
        boxShadow: '0px 6px 12px 1px rgba(20,20,20,0.4)'
    },
    loading: {
        textAlign: 'center'
    }
}));
const ImageCard = ({ selectTemplateImage, item, index }) => {
    const classes = useStyles();
    return (
        <ImageListItem
            component={'span'}
            style={{ cursor: 'pointer' }}
            onClick={() => selectTemplateImage(index)}
        >
            <Avatar
                component={'span'}
                alt={item.name}
                src={
                    GOOGLE_STORAGE_PUBLIC_URL +
                    item.ownerID +
                    TEMPLATE_PATH +
                    item.imagePath
                }
                variant="square"
                className={classes.avatar}
            />
            <ImageListItemBar
                title={item.imagePath?.split('/')[1]}
                // subtitle={<span>Format: {getTheFormat(item.imagePath)}</span>}
                actionIcon={
                    <IconButton
                        aria-label={`info about ${item.imagePath}`}
                        className={classes.icon}
                    >
                        {item.isSelect && (
                            <DoneIcon style={{ color: 'rgb(255,247,0)' }} fontSize="large" />
                        )}
                    </IconButton>
                }
            />
        </ImageListItem>
    );
};
const TemplateImagesList = ({ data }) => {
    const classes = useStyles();
    const [pageNum, setPageNum] = useState(1);
    const [lastElement, setLastElement] = useState(null);

    const { getMoreData, isLoading, templateImages, setTemplateImages } =
        useTemplatesList();

    const observer = useRef(
        new IntersectionObserver((entries) => {
            const first = entries[0];
            if (first.isIntersecting) {
                setPageNum((no) => no + 1);
            }
        })
    );

    useEffect(() => {
        // here we simulate adding new posts to List
        if (pageNum) {
            getMoreData(pageNum);
            console.log('call function to get new records', pageNum);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNum]);
    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);
    const selectTemplateImage = (index) => {
        templateImages[index].isSelect = !templateImages[index].isSelect;
        data.current = templateImages;
        setTemplateImages([...templateImages]);
    };

    return (
        <>
            <Grid container spacing={2}>
                {templateImages.map((item, index) => {
                    if (index === templateImages.length - 1) {
                        return (
                            <Grid item xs={2} md={3} key={index} ref={setLastElement}>
                                <ImageCard
                                    item={item}
                                    index={index}
                                    selectTemplateImage={selectTemplateImage}
                                />
                            </Grid>
                        );
                    }

                    return (
                        <Grid item xs={2} md={3} key={index}>
                            <ImageCard
                                item={item}
                                index={index}
                                selectTemplateImage={selectTemplateImage}
                            />
                        </Grid>
                    );
                })}
            </Grid>
            <div className={classes.loading}>{isLoading && <CircularProgress />}</div>
        </>
    );
};

export default TemplateImagesList;
