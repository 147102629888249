import React from 'react';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import { TEMPLATE_PATH } from 'src/utils/config';
import ImageImageList from '../bannerImages/BannerImagesListView';
import useTemplatesList from 'src/hooks/useTemplatesList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TemplatesList = () => {
  const classes = useStyles();

  const {
    getMoreData,
    isLoading,
    templateImages,
    handleSearch,
    deleteImage,
    ref
  } = useTemplatesList();

  return (
    <Page className={classes.root} title="Template Images">
      <Container maxWidth="lg">
        <Toolbar searchTemplate={handleSearch} />
        <ImageImageList
          ref={ref}
          data={templateImages}
          getMoreData={getMoreData}
          isLoading={isLoading}
          deleteImage={deleteImage}
          basePath={TEMPLATE_PATH}
        />
      </Container>
    </Page>
  );
};

export default TemplatesList;
