import React, { useState } from 'react';
import {
  Container,
  Grid,
  Box,
  Avatar,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DoneIcon from '@mui/icons-material/Done';
import Page from 'src/components/Page';

import { DEFAULT_OFFER_IMAGE } from 'src/utils/constants';
import ImageEditor from 'src/components/ImageEditor';
import { v1 } from 'uuid';
import { TEMPLATE_PATH } from 'src/utils/config';
import Services from 'src/services/Services';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  avatar: {
    height: "150px !important",
    width: '100% !important'
  },
  imageShadow: {
    boxShadow: '0px 6px 12px 1px rgba(20,20,20,0.4)'
  }
}));

const CreateTemplate = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate()
  const alertPosition = { horizontal: 'right', vertical: 'top' };
  const [defaultImages, setDefaultImages] = useState(DEFAULT_OFFER_IMAGE);
  const [selectedImage, setSelectedImage] = useState();
  const handleCaptureImages = ({ target }) => {
    let files = target.files;
    setSelectedImage(files[0]);
    console.log(files[0]);
  };
  const getTheFormat = (str) => {
    return str.split('.').pop();
  };
  const selectImage = (index) => {
    let selectedItem = null;
    defaultImages.map((item, idx) => {
      if (idx === index) {
        item.isSelect = !item.isSelect;
        selectedItem = item.isSelect ? item : null;
      } else {
        item.isSelect = false;
      }
      return item;
    });
    console.log(selectedItem);
    setSelectedImage(selectedItem);
    setDefaultImages([...defaultImages]);
  };
  // Convert Base64 to Blob
  function base64ToBlob(base64, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  const HandleUploadImage = async (base64String, name) => {
    try {
      const randomString = v1();
      const data = new FormData();
      const contentType = 'application/octet-stream'; // Adjust accordingly
      const base64Data = base64String.split(',')[1]; // Assuming base64String is data URL

      const blob = base64ToBlob(base64Data, contentType);
      data.append('file', blob, name + '.jpg');
      data.append('filePath', TEMPLATE_PATH + randomString);
      data.append('type', 'base64');
      await Services.imageUpload(data)
      var requestPayload = {
        imagePath: randomString + '/' + name + '.jpg'
      };
      await Services.saveTemplateData(requestPayload);
      enqueueSnackbar('Image uploaded successfully...!', {
        variant: 'success',
        anchorOrigin: alertPosition
      });
      navigate('/app/templates')
    } catch (e) {
      console.log(e)
      enqueueSnackbar('Something went wrong, Please try again...!', {
        variant: 'error',
        anchorOrigin: alertPosition
      });
    }
  };

  return (
    <Page className={classes.root} title="Create New Template">
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={3}>
            <Box display="flex" justifyContent="center" component={'span'}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file-dialog"
                type="file"
                onChange={(e) => {
                  handleCaptureImages(e);
                }}
                name="tempofferImgs"
              />
              <label htmlFor="raised-button-file-dialog">
                <Button component="span">Import from your computer</Button>
              </label>
            </Box>
            <Box
              style={{
                marginTop: 10,
                maxHeight: '80%',
                overflow: 'auto',
                padding: 10,
                background: 'white',
                scrollBehavior: 'smooth'
              }}
            >
              {defaultImages.map((item, index) => (
                <Grid item xs={12} style={{ marginBottom: 5 }} key={index}>
                  <ImageListItem
                    key={index}
                    component={'span'}
                    style={{ cursor: 'pointer' }}
                    onClick={() => selectImage(index)}
                  >
                    <Avatar
                      component={'span'}
                      alt={item.name}
                      src={`/static/images/${item.imagePath}`}
                      variant="square"
                      className={classes.avatar}
                    />
                    <ImageListItemBar
                      title={item.name}
                      subtitle={
                        <span>Format: {getTheFormat(item.imagePath)}</span>
                      }
                      actionIcon={
                        <IconButton
                          aria-label={`info about ${item.imagePath}`}
                          className={classes.icon}
                          size="large">
                          {item.isSelect && (
                            <DoneIcon
                              style={{ color: 'rgb(255,247,0)' }}
                              fontSize="large"
                            />
                          )}
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                </Grid>
              ))}
            </Box>
          </Grid>
          <Grid item xs={8} style={{ marginTop: 16 }}>
            <ImageEditor
              srcObj={selectedImage}
              handleUploadImage={HandleUploadImage}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
export default CreateTemplate;
