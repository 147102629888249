import * as markerjs2 from 'markerjs2';
import React, { useMemo, useRef, useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

const ImageEditor = ({ srcObj, handleUploadImage }) => {
  const [name, setName] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const alertPosition = { horizontal: 'right', vertical: 'top' };
  const src = useMemo(() => {
    if (srcObj && srcObj.isDefaultImage) {
      return `/static/images/${srcObj.imagePath}`;
    }
    if (srcObj) {
      return URL.createObjectURL(srcObj);
    }
  }, [srcObj]);
  console.log('src', src);
  const imgRef = useRef();
  function showMarkerArea() {
    if (imgRef.current !== null) {
      // create a marker.js MarkerArea
      const markerArea = new markerjs2.MarkerArea(imgRef.current);

      // attach an event handler to assign annotated image back to our image element
      markerArea.addEventListener('render', (event) => {
        if (imgRef.current) {
          imgRef.current.src = event.dataUrl;
        }
      });
      // launch marker.js

      // enable redo, notes, zoom, and clear buttons (hidden by default)
      markerArea.uiStyleSettings.redoButtonVisible = true;
      // markerArea.uiStyleSettings.notesButtonVisible = true;
      // markerArea.uiStyleSettings.zoomButtonVisible = true;
      // markerArea.uiStyleSettings.zoomOutButtonVisible = true;
      markerArea.uiStyleSettings.clearButtonVisible = true;
      markerArea.uiStyleSettings.maxWidth = '80%';
      markerArea.uiStyleSettings.maxHeight = '70%';

      markerArea.uiStyleSettings.zIndex = 11111111;
      markerArea.settings.displayMode = 'popup';
      markerArea.show();
    }
  }
  const handleSave = () => {
    console.log(imgRef.current.src);
    if (!name) {
      enqueueSnackbar('Please enter name', {
        variant: 'error',
        anchorOrigin: alertPosition
      });
      return false;
    }
    handleUploadImage(imgRef.current.src, name);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  return (
    <>
      {src && (
        <>
          <Grid item md={6} xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Name"
              name="name"
              onChange={handleNameChange}
              value={name}
              variant="outlined"
            />
          </Grid>
          <br />
          <img
            ref={imgRef}
            src={src}
            alt="template"
            style={{ maxWidth: '80%', maxHeight: '70%' }}
            onClick={showMarkerArea}
          />
          <br />
          <Button
            color="primary"
            variant="contained"
            type="submit"
            style={{ float: 'right' }}
            onClick={handleSave}
          >
            Save
          </Button>
        </>
      )}
    </>
  );
};

export default ImageEditor;
