export const DEFAULT_OFFER_IMAGE = [
  {
    name: 'Offer1',
    imagePath: 'defaultOfferImages/offer1.jpg',
    isDefaultImage: true,
    type: 'image/jpg'
  },
  {
    name: 'Offer2',
    imagePath: 'defaultOfferImages/offer2.jpg',
    isDefaultImage: true,
    type: 'image/jpg'
  },
  {
    name: 'Offer3',
    imagePath: 'defaultOfferImages/offer3.jpg',
    isDefaultImage: true,
    type: 'image/jpg'
  },
  {
    name: 'Offer4',
    imagePath: 'defaultOfferImages/offer4.jpg',
    isDefaultImage: true,
    type: 'image/jpg'
  }
];

export const DEFAULT_IMAGE_FOLDER = 'defaultOfferImages';
