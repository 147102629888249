import React, { useEffect, useRef, useState } from 'react';
import { forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@mui/styles';
import ImageListItem from '@mui/material/ImageListItem';
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  ImageListItemBar,
  IconButton,
  Typography
} from '@mui/material';
import { BANNER_PATH, GOOGLE_STORAGE_PUBLIC_URL } from 'src/utils/config';
import ConfirmDialog from '../store/StoreDetailView/ConfirmDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';

const useStyles = makeStyles((theme) => ({
  root: {},
  ImageList: {
    marginTop: '10px !important'
  },
  avatar: {
    height: "200px !important",
    width: '100% !important'
  },
  loading: {
    textAlign: 'center'
  },
  statusBadge: {
    marginLeft: 10,
    borderRadius: 10,
    padding: 3,
    paddingLeft: 15,
    paddingRight: 15
  },
  active: {
    color: '#ffff',
    backgroundColor: 'rgb(68 163 72 / 89%)'
  },
  expired: {
    color: '#ffff',
    backgroundColor: 'rgb(244 67 54 / 92%)'
  },
  submitted: {
    color: '#ffff',
    backgroundColor: 'rgb(49 161 214 / 89%)'
  },
  custPadding: {
    paddingBottom: 5,
    paddingTop: 5
  }
}));

const ImageImageList = forwardRef((props, ref) => {
  const {
    data,
    getMoreData,
    isLoading,
    deleteImage,
    basePath = BANNER_PATH
  } = props;
  const classes = useStyles();
  // tracking on which page we currently are
  const [page, setPage] = useState(0);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imagesData, setImagesdata] = useState([]);
  // add loader refrence
  const loader = useRef(null);

  useEffect(() => {
    var options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0
    };
    // initialize IntersectionObserver
    // and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
    imagesData.map((item) => (item.isSelect = false));
    console.log(imagesData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setImagesdata(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    // here we simulate adding new posts to List
    if (page) {
      getMoreData(page);
      console.log('call function to get new records', page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setPage((page) => page + 1);
    }
  };
  const selectImage = (id) => {
    //let image= selectedImages
    console.log(imagesData);
    let isNew = !selectedImages.includes(id);
    if (isNew) {
      setSelectedImages([...selectedImages, id]);
    } else {
      //selectedImages.splice(id,1)
      const filteredItems = selectedImages.filter(function (item) {
        return item !== id;
      });
      setSelectedImages(filteredItems);
    }
    const modifiedArray = imagesData.map((item) => {
      if (item._id === id) {
        item.isSelect = !item.isSelect;
      }
      return item;
    });
    setImagesdata(modifiedArray);
  };
  const deleteImages = async () => {
    console.log(imagesData);

    console.log(selectedImages);
    const afterdeleteArray = imagesData.filter(
      (item) => !selectedImages.includes(item._id)
    );
    //const deleteImg = await Services.deleteImages(selectedImages)
    deleteImage(selectedImages);
    console.log(afterdeleteArray);
    setImagesdata(afterdeleteArray);
    setSelectedImages([]);
  };
  // Pass the ref to the useImperativeHandle hook
  useImperativeHandle(ref, () => ({
    resetPage: () => {
      if (page === 1) getMoreData(1);
      setPage(1);
    }
  }));
  return (
    <Box style={{ marginTop: 5 }}>
      {selectedImages.length > 0 && (
        <Typography variant="body2" style={{ textAlign: 'center' }}>
          <ConfirmDialog
            deleteItem={deleteImages}
            buttonText="Delete Image(s)"
            message="Are you sure, Do you want to delete the images?"
          >
            <DeleteIcon></DeleteIcon>
          </ConfirmDialog>
        </Typography>
      )}
      <Grid container className={classes.ImageList} direction="row" spacing={2}>
        {imagesData.map((item) => (
          <Grid item xs={4} key={item._id}>
            <ImageListItem
              component={'span'}
              onClick={() => selectImage(item._id)}
            >
              <Avatar
                component={'span'}
                alt={item.imagePath}
                src={
                  GOOGLE_STORAGE_PUBLIC_URL +
                  item.ownerID +
                  basePath +
                  item.imagePath.replace(" ", '_')
                }
                variant="square"
                className={classes.avatar}
              />
              <ImageListItemBar
                classes={{ subtitle: classes.custPadding }}
                title={item.imagePath.split('/')[1]}
                subtitle={
                  <span>
                    Uploaded Date: {item.createdAt.slice(0, 10)}
                    {item.status === 1 ? (
                      <span
                        className={`${classes.statusBadge} ${classes.submitted}`}
                      >
                        Submitted
                      </span>
                    ) : item.status === 2 ? (
                      <span
                        className={`${classes.statusBadge} ${classes.active}`}
                      >
                        Approved
                      </span>
                    ) : (
                      <span
                        className={`${classes.statusBadge} ${classes.expired}`}
                      >
                        Rejected
                      </span>
                    )}
                  </span>
                }
                actionIcon={
                  <IconButton
                    aria-label={`info about ${item.imagePath}`}
                    className={classes.icon}
                    size="large">
                    {item.isSelect && (
                      <DoneIcon
                        style={{ color: 'rgb(255,247,0)' }}
                        fontSize="large"
                      />
                    )}
                  </IconButton>
                }
              />
            </ImageListItem>
          </Grid>
        ))}
      </Grid>
      <div className={classes.loading} ref={loader}>
        {isLoading && <CircularProgress />}
      </div>
    </Box>
  );
});

export default ImageImageList;
