import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  SvgIcon
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  textFeild: {
    '& .MuiInputBase-root': {
      height: theme.spacing(4)
    }
  }
}));

const Toolbar = ({ className, searchTemplate, ...rest }) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const searchHandler = (event) => {
    const searchString = event.target.value;
    var filter = {};

    console.log('search string contains letter');
    if (searchString.length > 3) {
      filter = {
        searchString: searchString,
        type: 'name'
      };
      searchTemplate(filter);
    } else if (searchString === undefined || searchString === '') {
      searchTemplate();
    }

    // console.log(searchString)
  };
  return (
    <Box display="flex" justifyContent="space-between">
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          navigate('/app/templates/create');
        }}
      >
        Create New
      </Button>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon fontSize="small" color="action">
                <SearchIcon />
              </SvgIcon>
            </InputAdornment>
          )
        }}
        placeholder="Enter template name"
        variant="outlined"
        onChange={searchHandler}
        classes={{ root: classes.textFeild }}
      />
    </Box>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
