import { useState, useRef, useContext } from 'react';
import { GlobalContext } from 'src/context/GlobalState';
import Services from 'src/services/Services';

const useTemplatesList = () => {
  const ref = useRef(null);
  const { setLoading } = useContext(GlobalContext);
  let [templateImages, setTemplateImages] = useState([]);
  const isResponseEmpty = useRef(false);
  const searchObj = useRef({});
  const [isLoading, setIsLoading] = useState(false);
  const getTemplateImages = async (page = 1) => {
    try {
      if (!isResponseEmpty.current) {
        setIsLoading(true);
        const resultObj = await Services.getTemplateImages(
          page,
          searchObj.current
        );
        const responseData = resultObj.data.map((d) => ({
          ...d,
          isSelected: d.isSelected ? true : false
        }));
        console.log(responseData);
        if (responseData.length > 0) {
          setTemplateImages([...templateImages, ...responseData]);
        }
        setIsLoading(false);
        if (resultObj.data.length === 0) {
          isResponseEmpty.current = true;
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const deleteImage = async (selectedImages) => {
    try {
      setLoading(true);

      await Services.deleteTemplateImages(selectedImages);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const getMoreData = (page) => {
    getTemplateImages(page);
    console.log('getting info on page number', page);
  };
  const handleSearch = async (searchStr) => {
    console.log(searchStr, '---searchStr');
    try {
      searchObj.current = searchStr;
      templateImages = [];
      isResponseEmpty.current = false;
      ref.current.resetPage();
    } catch (error) {
      console.log(error);
    }
  };
  return {
    handleSearch,
    getMoreData,
    deleteImage,
    isLoading,
    ref,
    templateImages,
    setTemplateImages,
    getTemplateImages,
    searchObj,
    isResponseEmpty
  };
};

export default useTemplatesList;
